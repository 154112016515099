<template>
  <ChromeHeader />

  <main class="page">
    <RouterView v-slot="{ Component }">
      <Suspense>
        <PageTransition mode="out-in" @before-leave="onBeforeLeave">
          <component
            :is="resource.template === 'notfound' ? Notfound : Component"
            :key="route.fullPath"
            :content="resource.content"
          />
        </PageTransition>
      </Suspense>
    </RouterView>
  </main>

  <ChromeFooter :content="resource.content" />

  <DevEnvSwitcher />
  <DevGrid />
</template>

<script setup lang="ts">
import '@monofront/v-components/style.css'

import { useHead, useHeadRaw } from '@vueuse/head'
import { computed, onBeforeMount, onMounted } from 'vue'
import { useRoute } from 'vue-router'

import PageTransition from '@/animations/PageTransition.vue'
import ChromeFooter from '@/components/chrome/Footer.vue'
import ChromeHeader from '@/components/chrome/Header.vue'
import DevEnvSwitcher from '@/components/dev/EnvSwitcher.vue'
import DevGrid from '@/components/dev/Grid.vue'
import {
  googleSite,
  gtmId,
  onetrustId,
  useGtm,
  useOneTrust,
} from '@/config/app'
import { getCountry } from '@/config/countries'
import favicon from '@/config/favicon'
import { prefers } from '@/core/prefers'
import { updateResource } from '@/core/resource'
import { useChromeStore } from '@/stores/chrome'
import { useResourceStore } from '@/stores/resource'
import { useViewport } from '@/utils/aware'
import { logger } from '@/utils/logger'
import Notfound from '@/views/404.vue'

import type { ResourceHead, ResourceSingle } from '@/stores/resource'

const chrome = useChromeStore()
const resource = useResourceStore()
const route = useRoute()
const { lang } = route.params
const country = getCountry()

useViewport()

logger.log('App setup')

// First rendering, SSR
if (import.meta.env.SSR && route.meta.state) {
  updateResource(route.meta.state.resource as ResourceSingle)
}

/**
 * User prefs
 */
if (!import.meta.env.SSR) {
  prefers.init()
  logger.log('prefers', prefers)
}

/**
 * Head
 */
const title = computed(
  () => resource.head?.title || resource.content?.title || ''
)
const meta = computed(() => {
  const meta = [...(resource.head?.meta || []), ...favicon.meta]

  meta.push({ name: 'environment', content: import.meta.env.VITE_RELEASE })

  googleSite &&
    meta.push({ name: 'google-site-verification', content: googleSite })

  return meta
})

const preload: ResourceHead['style'] = []

// Add resources to preload (ex: hero image, visible into the viewport, …)
// if (resource.content.hero) {
//   preload.push({
//     rel: 'preload',
//     as: 'image',
//     href: resource.content.hero.picture.sets[2560],
//     // media: '(max-width: 600px)',
//     imagesrcset: `
//         ${resource.content.hero.picture.sets[2560]} 2560w,
//         ${resource.content.hero.picture.sets[1280]} 1280w,
//         ${resource.content.hero.picture.sets[960]} 960w`,
//     imagesizes: '100vw',
//     crossorigin: true,
//   })
// }

const link = computed(() => {
  const link = [...(resource.head?.link || []), ...favicon.link]

  return (
    link
      // Preconnect CMS
      // <link rel="preconnect" href="https://foo.com/" crossorigin>
      .concat({
        rel: 'preconnect',
        href: import.meta.env.VITE_API_DOMAIN,
        crossorigin: true,
      })
      .concat(preload)
  )
})

// LD=JSON as it will be used with `useHeadRaw()`
const scriptRaw = computed(() => {
  const headScripts: ResourceHead['script'] = resource.head?.script || []
  const chromeScripts: ResourceHead['script'] = chrome.script || []

  const mergedScripts = [
    ...chromeScripts.filter(
      s => s.type === 'application/ld+json' || s.innerHTML
    ),
    ...headScripts.filter(s => s.type === 'application/ld+json' || s.innerHTML),
  ]

  if (useOneTrust) {
    let id = onetrustId[country]

    if (import.meta.env.VITE_RELEASE !== 'production') {
      id += '-test'
    }

    const onetrustScripts: Record<string, string>[] = [
      {
        src: `https://cdn.cookielaw.org/consent/${id}/OtAutoBlock.js`,
      },
      {
        /* eslint-disable quote-props */
        src: 'https://cdn.cookielaw.org/scripttemplates/otSDKStub.js',
        charset: 'UTF-8',
        'data-document-language': 'true',
        'data-domain-script': id,
        /* eslint-enable quote-props */
      },
      { children: 'function OptanonWrapper() { }' },
    ]

    mergedScripts.push(...onetrustScripts)
  }

  if (useGtm) {
    const id = gtmId[country]

    const gtmScripts: Record<string, string | boolean>[] = [
      {
        innerHTML: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
          new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
          j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
          'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
          })(window,document,'script','dataLayer','${id}');`,
      },
      {
        innerHTML:
          // eslint-disable-next-line max-len
          'window.dataLayer=window.dataLayer||[];window.dataLayer.push({originalLocation:document.location.protocol+"//"+document.location.hostname+document.location.pathname+document.location.search});',
        body: true,
      },
    ]

    mergedScripts.push(...gtmScripts)
  }

  if (import.meta.env.VITE_TURNSTILE_KEY) {
    mergedScripts.push({
      src: 'https://challenges.cloudflare.com/turnstile/v0/api.js',
      async: true,
      defer: true,
    })
  }

  return mergedScripts
})

const script = computed(() => {
  const headScripts: ResourceHead['script'] = resource.head?.script || []
  const chromeScripts: ResourceHead['script'] = chrome.script || []

  const mergedScripts = [
    ...chromeScripts.filter(
      s => s.type !== 'application/ld+json' && !s.innerHTML
    ),
    ...headScripts.filter(
      s => s.type !== 'application/ld+json' && !s.innerHTML
    ),
  ]

  return mergedScripts
})

const noscript = computed(() => {
  const scripts: ResourceHead['script'] = []

  if (useGtm) {
    const id = gtmId[country]

    const gtmScripts: Record<string, string | boolean>[] = [
      {
        children: `<iframe src="https://www.googletagmanager.com/ns.html?id=${id}"
          height="0" width="0" style="display:none;visibility:hidden"></iframe>`,
        body: true,
      },
    ]

    scripts.push(...gtmScripts)
  }

  return scripts
})

useHead({
  title,
  link,
  script,
  noscript,
  // Other properties…
  htmlAttrs: () => resource?.head?.htmlAttrs,
  bodyAttrs: () => resource?.head?.bodyAttrs,
})
useHeadRaw({
  meta, // using raw for meta avoid ' to be escaped on SSR rendering…
  script: scriptRaw,
})

/**
 * Lifecycle
 */
onBeforeMount(() => {
  // Set OT language
  if (typeof lang === 'string') {
    window.OneTrust?.changeLanguage(lang)
  }
})
onMounted(() => {
  logger.log('App mounted')
  const event = new Event('appready')
  setTimeout(() => document.dispatchEvent(event), 0)
})

const onBeforeLeave = () => {
  if (route.meta.state) {
    // Update state during the transition
    // when the previous view is leaving to avoid content errors.
    if (route.meta.state) {
      updateResource(route.meta.state.resource as ResourceSingle)
    }
  }
}
</script>

<style lang="scss">
/* stylelint-disable-next-line selector-max-id */
#app {
  // width: 100vw;
  // padding-top: $header-height;
}
</style>
